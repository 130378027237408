$(".js-hero-slider").slick({
  arrows: true,
  autoplay: true,
  fade: true,
  mobileFirst: true,
  cssEase: "linear",
  dots: false,
  prevArrow: false,
  nextArrow: '<button type="button" class="hero-slider__btn" role="button" aria-label="Next slide"></button>',
});

$(".js-vr-slider").slick({
  arrows: false,
  variableWidth: true,
  mobileFirst: true,
  swipeToSlide: false,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        arrows: true,
      },
    },
  ],
});

$(".js-ps-slider").slick({
  arrows: false,
  variableWidth: true,
  mobileFirst: true,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        dots: true,
      },
    },
    {
      breakpoint: 1440,
      settings: {
        arrows: true,
      },
    },
  ],
});

$(".js-service-slider").slick({
  arrows: false,
  variableWidth: true,
  mobileFirst: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: "unslick",
    },
  ],
});

$(".js-comments-slider").slick({
  arrows: true,
  variableWidth: true,
  mobileFirst: true,
  swipeToSlide: true,
  prevArrow:
    '<button type="button" class="comments-slider__btn comments-slider__btn--prev" role="button" aria-label="Prev slide"></button>',
  nextArrow:
    '<button type="button" class="comments-slider__btn comments-slider__btn--next" role="button" aria-label="Next slide"></button>',
});
